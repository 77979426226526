import React, { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../util/Colors";
import Icon, { Icons } from "../components/elements/Icon";
const Logo = require("../assets/images/logo-inner.png");
const profileLogo = require("../assets/images/user.png");
import { useNavigate } from "react-router-dom";
import { localStorageService } from "../services";
import routePaths from "../config/routepaths.config";
import { useDispatch } from "react-redux";
import { setLogoDropdown, setOrganization } from "../redux/user/user.actions";
import { Roles } from "../models/enums/RolesEnum";

const HeaderContainer = styled.div`
   position: fixed;
   top: 0;
   height: 64px;
   padding: 12px 0;
   display: flex;
   width: 100%;
   background: ${Colors.white};
   box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
   z-index: 2;
`;
export const Container = styled.div`
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
   max-width: 1140px;
   
   }
`;
export const ContentContainer = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
   }
`;
export const NavHeader = styled.div`
  display:flex;
   }
`;
export const HumburgerMenu = styled.div`
  display:none;
  align-items: center;
  margin: 0 15px 0px 0;
  cursor:pointer;
  @media screen and (max-width:767px) {
   display: flex;
  }
}
`;
export const LogoWrapper = styled.div`
    width:137px;
    height:40px;
   }
`;
export const LogoImage = styled.img`
   width: 100%;
`;
const CrossIcon = styled.div`
   position: absolute;
   right: 10px;
   top: 10px;
   cursor: pointer;
   display: none;
   @media screen and (max-width: 767px) {
      display: block;
   }
`;
export const NavMenu = styled.div<any>`
   margin: 0 0 0 90px;
   display: flex;
   align-items: center;
   @media screen and (max-width: 767px) {
      left: -250px;
      margin: 0;
      transition: 0.5s;
      position: fixed;
      width: 250px;
      height: 100%;
      background: #eee;
      top: 0;
      margin: 0;
      ${[
         (props) =>
            props.humburgerMenu &&
            ` left:0px;
    }`,
      ]}
   }
`;
export const ListWrapper = styled.div`
   margin:0px;
   padding:0px;
   list-style:none;
   display: flex;
   gap:30px;
   @media screen and (max-width:767px){
   flex-direction:column;
   padding: 30px 0 0 0;
   gap:0px;
   width: 100%;
   height: 100%;
   }
}
`;
export const OrgLogoContainer = styled.div`
   height: 50px;
   width: 50px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 20px;
   cursor: pointer;
   position: relative;
`;
export type DropDownProps = {
   display: string;
};
export const DropdownMenu = styled.div<any>`
   position: absolute;
   top: 60px;
   left: -130px;
   min-width: 160px;
   font-size: 14px;
   text-align: left;
   list-style: none;
   background-color: #fff;
   border-radius: 4px;
   box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
   display: ${(props) => (props.display === "1" ? "block" : "none")};
`;
export const MenuItem = styled.div`
   display: flex;
   padding: 10px 20px;
   font-size: 15px;
   font-weight: 500;
   white-space: nowrap;
   line-height: 17px;
   border: 1px solid #f2f2f2;
`;
const SubNavItem = styled.div<{ active: boolean }>`
   font-weight: 600;
   font-size: 15px;
   color: ${[(props) => (props.active ? Colors.black1 : Colors.darkGray)]};
   cursor: pointer;
   position: relative;
   @media screen and (max-width: 767px) {
      padding: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   }
   &:hover {
      color: ${Colors.black1};
   }
   ${[
      (props) =>
         props.active
            ? `&::before{
      content:"";
      background: linear-gradient(127.15deg, #386EE2 17.06%, #6F24BC 93.36%);
      height: 2px;
      width:100%;
      position:absolute;
      bottom:-21px;
      left: 0;
      @media screen and (max-width:767px){
         display:none;
      }
   }`
            : "",
   ]}
`;
const UserProfile = styled.div`
   height: 50px;
   width: 50px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 20px;
   cursor: pointer;
   position: relative;
   border: 1px solid ${Colors.grey5};
`;
const ProfileImage = styled.img`
   width: 100%;
   border-radius: 50%;
   height: 50px;
`;
enum PageStateEnum {
   Home = "Home",
   Devices = "Devices",
   Tests = "Tests",
   SuperAdmin = "SuperAdmin",
}

const Routes = {
   Home: "/home",
   Devices: "/devices",
   Tests: "/tests",
   SuperAdmin: "/superAdmin",
};

const Header = (props) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const activeTab = localStorageService?.getActiveTab();
   const organization = props?.organization;
   const activeOrg = organization?.organization?.active;
   // State
   const [pageState, setPageState] = React.useState(PageStateEnum.Home);

   // Actions
   const onPageTabs = (pageState: any) => {
      setPageState(PageStateEnum[pageState]);
      props?.setHumburgerMenu(false);
      navigate(Routes[pageState]);
   };

   const onAddAccount = () => {
      navigate(routePaths.VIEW_ACCOUNT);
      dispatch(setLogoDropdown(false));
   };

   const openMyProfile = () => {
      navigate(routePaths.MY_PROFILE, {
         state: {
            userId: organization?._id,
         } as any,
      });
      dispatch(setLogoDropdown(false));
   };

   const onLogOut = () => {
      localStorageService.clearLocalStorage();
      dispatch(setLogoDropdown(false));
      dispatch(setOrganization(""));
      navigate(routePaths.LOGIN);
   };

   const handleMenuButton = () => {
      if (props?.logoDropdown) {
       
         return dispatch(setLogoDropdown(!props?.logoDropdown));
      }
      return;
   };

   useEffect(() => {
      const pathname = window.location.pathname;
      const testId = window.location.href.substring(
         window.location.href.lastIndexOf("/") + 1
      );
      const route = pathname.substring(1);
      setPageState(
         PageStateEnum[route.charAt(0).toUpperCase() + route.slice(1)]
      );
      if (pathname === `/tests/${testId}`) {
         setPageState(PageStateEnum.Tests);
         navigate(`/tests/${testId}`);
      } else if (activeTab) {
         setPageState(PageStateEnum[activeTab]);
         localStorageService.storeActiveTab("");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pageState, activeTab]);

   return (
      <>
         <HeaderContainer onClick={() => handleMenuButton()}>
            <Container>
               <ContentContainer>
                  <NavHeader>
                     <HumburgerMenu
                        onClick={() =>
                           props?.setHumburgerMenu(!props?.humburgerMenu)
                        }
                     >
                        <Icon
                           icon={Icons.MenuSolid}
                           size={18}
                           color={Colors.black}
                        />
                     </HumburgerMenu>
                     <LogoWrapper>
                        <LogoImage src={Logo} />
                     </LogoWrapper>
                     <NavMenu humburgerMenu={props?.humburgerMenu}>
                        <CrossIcon
                           onClick={() =>
                              props?.setHumburgerMenu(!props?.humburgerMenu)
                           }
                        >
                           <Icon
                              icon={Icons.Cancel}
                              size={14}
                              color={Colors.blue}
                           />
                        </CrossIcon>
                        <ListWrapper>
                           {organization.role !== Roles.User &&
                              organization.role !== undefined && (
                                 <>
                                    <SubNavItem
                                       onClick={() =>
                                          onPageTabs(PageStateEnum.Home)
                                       }
                                       active={pageState === PageStateEnum.Home}
                                    >
                                       {PageStateEnum.Home}
                                    </SubNavItem>
                                    <SubNavItem
                                       onClick={() =>
                                          onPageTabs(PageStateEnum.Devices)
                                       }
                                       active={
                                          pageState === PageStateEnum.Devices
                                       }
                                    >
                                       Devices
                                    </SubNavItem>
                                 </>
                              )}
                           {!activeOrg && organization.role === Roles.Admin ? (
                              false
                           ) : (
                              <SubNavItem
                                 onClick={() => onPageTabs(PageStateEnum.Tests)}
                                 active={pageState === PageStateEnum.Tests}
                              >
                                 Tests
                              </SubNavItem>
                           )}
                           {organization.role === Roles.SuperAdmin && (
                              <SubNavItem
                                 onClick={() =>
                                    onPageTabs(PageStateEnum.SuperAdmin)
                                 }
                                 active={pageState === PageStateEnum.SuperAdmin}
                              >
                                 Super Admin
                              </SubNavItem>
                           )}
                        </ListWrapper>
                     </NavMenu>
                  </NavHeader>
                  <UserProfile>
                     <ProfileImage
                        src={
                           organization.organization?.logoUrl
                              ? organization.organization?.logoUrl
                              : profileLogo
                        }
                        onClick={() =>
                           dispatch(setLogoDropdown(!props?.logoDropdown))
                        }
                     />
                     <DropdownMenu display={props?.logoDropdown ? "1" : "0"}>
                        <MenuItem onClick={openMyProfile}>
                           <Icon
                              icon={Icons.User}
                              size={16}
                              margin="0 10px 0 0"
                              color={Colors.darkGray}
                           />
                           My Profile
                        </MenuItem>
                        {organization?.role !== Roles.User && (
                           <MenuItem onClick={() => onAddAccount()}>
                              <Icon
                                 icon={Icons.UserCircle}
                                 size={16}
                                 margin="0 10px 0 0"
                                 color={Colors.darkGray}
                              />
                              View Account
                           </MenuItem>
                        )}
                        {organization?.role !== Roles.User && (
                           <MenuItem>
                              <Icon
                                 icon={Icons.Wallet}
                                 size={16}
                                 margin="0 10px 0 0"
                                 color={Colors.darkGray}
                              />
                              Next bill on 5/1/22
                           </MenuItem>
                        )}
                        <MenuItem onClick={() => onLogOut()}>
                           <Icon
                              icon={Icons.SignOut}
                              size={16}
                              margin="0 10px 0 0"
                              color={Colors.darkGray}
                           />
                           Logout
                        </MenuItem>
                     </DropdownMenu>
                  </UserProfile>
               </ContentContainer>
            </Container>
         </HeaderContainer>
      </>
   );
};
export default Header;
