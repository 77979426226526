
const routePaths = {
   LOGIN: "/login",
   REGISTER: "/register",
   RESET_PASSWORD: "/resetPassword",
   FORGOT_PASSWORD: "/forgotPassword",
   SET_PASSWORD: "/setPassword",
   STRIPE: "/stripe",
   ABOUT: "/about",
   VIDEOS: "/videos",
   BILLING: "/billing",
   HOME: "/home",
   DEVICES: "/devices",
   TESTS: "/tests",
   SUPER_ADMIN: "/superAdmin",
   VIEW_ACCOUNT: "/viewAccount",
   TEST_DETAIL: `/tests/:id`,
   ADD_USER: "/addUser",
   UPDATE_USER: "/updateUser",
   ACCOUNT_DETAIL: "/accountDetail",
   MY_PROFILE: "/myProfile",
   ADD_ACCOUNT: "/addAccount"
};

export default routePaths;