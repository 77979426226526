import { lazy, Suspense, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardRoute from "./DashboardRoute";
import AuthRoute from "./AuthRoute";
import { AppContext } from "../components/context/AppContext";
import Loader from "../components/elements/Loader";
import LoaderContainer from "../components/elements/LoaderContainer";
import routePaths from "../config/routepaths.config";

const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const AddAccount = lazy(() => import("../pages/AddAccount"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/ForgotPassword"));
const SetPassword = lazy(() => import("../pages/SetPassword"));
const Home = lazy(() => import("../pages/Home"));
const DeviceView = lazy(() => import("../pages/DeviceView"));
const TestView = lazy(() => import("../pages/TestView"));
const EnterPayment: any = lazy(() => import("../pages/StripePayment"));
const Videos = lazy(() => import("../pages/Videos"));
const ViewAccount = lazy(() => import("../pages/ViewAccount"));
const Billing = lazy(() => import("../pages/Billing"));
const TestDetail =  lazy(() => import("../pages/TestDetail"));
const AccountDetail =  lazy(() => import("../pages/AccountDetail"));
const AddUser = lazy(() => import("../pages/AddUser"));
const UpdateUser = lazy(() => import("../pages/UpdateUser"));
const SuperAdmim = lazy(() => import("../pages/SuperAdmin"));

const About = () => {
   return <h1>About</h1>;
};

const Loading = (
   <LoaderContainer>
      <Loader />
   </LoaderContainer>
);

export const BaseRoutes = () => {
   const [app, setApp] = useState(null);
   const appValue = useMemo(() => ({ app, setApp }), [app, setApp]);
   return (
      <AppContext.Provider value={appValue}>
         <Suspense fallback={Loading}>
            <Routes>
               <Route
                  path="/"
                  element={
                     <AuthRoute>
                        <Login />
                     </AuthRoute>
                  }
               />
               <Route
                  path={routePaths.LOGIN}
                  element={
                     <AuthRoute>
                        <Login />
                     </AuthRoute>
                  }
               />
               <Route
                  path={routePaths.REGISTER}
                  element={
                     <AuthRoute>
                        <Register />
                     </AuthRoute>
                  }
               />
               <Route
                  path={routePaths.FORGOT_PASSWORD}
                  element={
                     <AuthRoute>
                        <ForgotPassword />
                     </AuthRoute>
                  }
               />
               <Route
                  path={routePaths.SET_PASSWORD}
                  element={
                     <AuthRoute>
                        <SetPassword />
                     </AuthRoute>
                     }
               />
               <Route
                  path={routePaths.RESET_PASSWORD}
                  element={
                     <AuthRoute>
                        <SetPassword />
                     </AuthRoute>
                     }
               />
               <Route
                  path={routePaths.STRIPE}
                  element={
                     <AuthRoute>
                        <EnterPayment />
                     </AuthRoute>
                  }
               />

               <Route
                  path={routePaths.ABOUT}
                  element={
                     <DashboardRoute>
                        <About />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.VIDEOS}
                  element={
                     <DashboardRoute>
                        <Videos />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.BILLING}
                  element={
                     <DashboardRoute>
                        <Billing />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.ADD_ACCOUNT}
                  element={
                     <DashboardRoute>
                        <AddAccount />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.HOME}
                  element={
                     <DashboardRoute>
                        <Home />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.DEVICES}
                  element={
                     <DashboardRoute>
                        <DeviceView />
                     </DashboardRoute>

                  }
               />
               <Route
                  path={routePaths.TESTS}
                  element={
                     <DashboardRoute>
                        <TestView />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.TEST_DETAIL}
                  element={
                     <DashboardRoute>
                        <TestDetail />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.SUPER_ADMIN}
                  element={
                     <DashboardRoute>
                        <SuperAdmim />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.VIEW_ACCOUNT}
                  element={
                     <DashboardRoute>
                        <ViewAccount />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.ACCOUNT_DETAIL}
                  element={
                     <DashboardRoute>
                        <AccountDetail />
                     </DashboardRoute>
                  }
               />
               <Route path={routePaths.MY_PROFILE}
                  element={
                     <DashboardRoute>
                        <UpdateUser />
                     </DashboardRoute>
                  }
               />
               <Route
                  path={routePaths.ADD_USER}
                  element={
                     <DashboardRoute>
                        <AddUser />
                     </DashboardRoute>
                  }
               />
                 <Route
                  path={routePaths.UPDATE_USER}
                  element={
                     <DashboardRoute>
                        <UpdateUser />
                     </DashboardRoute>
                  }
               />
               <Route path="*" element={<NotFound />} />
            </Routes>
         </Suspense>
      </AppContext.Provider>
   );
};

const NotFound = () => {
   return <h1>Not Found</h1>;
};

export default BaseRoutes;
